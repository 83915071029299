import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useLocation } from "@reach/router";
import { Button, Form, Input, message, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { isEmpty, map, toLower, trim } from "lodash";
import forEach from "lodash.foreach";
import get from "lodash.get";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getPairingUser from "../../../newApi/pairing/getPairingUser";
import validatePlacement from "../../../newApi/pairing/validatePlacement";
import validateSponsorName from "../../../newApi/pairing/validateSponsorName";
import getPlacements from "../../../newApi/placement/getPlacements";
import createUser from "../../../newApi/user/createUser";
import { triggerModal } from "../../../redux/actions/app-actions";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import RequirePairingAuthWrapper from "../../general/components/RequirePairingAuthWrapper";
import validateUserName from "../../../newApi/pairing/validateUserName";

// markup
const RegisterPage = (props) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [validPosition, setValidPosition] = useState([]);
  const [disableInput, setDisableInput] = useState(false);
  const [placementDisabled, setPlacementDisabled] = useState(true);
  const accessKey = get(props.user, "accessKey");
  const user = get(props, "user.user");
  // console.log(user);
  let timeoutFunc;

  const positionList = [
    {
      key: "L",
      value: "Left",
    },
    {
      key: "R",
      value: "Right",
    },
  ];

  useEffect(() => {
    // form.setFieldsValue({ username: " ", password: " " });
    form.resetFields();
    let query = queryString.parse(location.search);
    // console.log("query", query);
    if (!isEmpty(query)) {
      if (get(query, "placement")) {
        form.setFieldsValue({ placement: query.placement });
      }
      if (get(query, "position")) {
        let positionQuery = [];
        if (get(query, "position") === "R") {
          positionQuery.push(positionList[1]);
        } else {
          positionQuery.push(positionList[0]);
        }
        setValidPosition(positionQuery);
        form.setFieldsValue({ position: get(query, "position") });
      }
      // if (get(query, "sponsor")) {
      //   form.setFieldsValue({ sponsor: get(query, "sponsor") });
      // }
      setDisableInput(true);
      setPlacementDisabled(true);
    }
    // form.setFieldsValue({ username: "", password: "" });
  }, []);

  useEffect(() => {
    // console.log("get packages data");
  }, []);

  function submitRegister() {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        // console.log("values", values);
        let sponsorUsername = get(values, "sponsor");

        let correctValues = {};
        correctValues = {
          username: toLower(get(values, "username")),
          name: get(values, "username"),
          email: get(values, "email"),
          password: get(values, "password"),
          sponsorName: get(values, "sponsor"),
          placementName: toLower(get(values, "placement")),
          contactNo: get(values, "contact"),
          // packageId: get(values, "package"),
          position: get(values, "position"),
          requestUserId: get(user, "profile.pairingUserId"),
          parentId: get(user, "_id"),
        };
        // console.log(correctValues);

        createUser(correctValues)
          .then((res2) => {
            // console.log(res2);
            setLoading(false);
            message.success(t("success"));
            // navigate(
            //   routes.nodeTeam.to({ userId: get(res2, "_id") })
            // );
            window.location.assign(
              routes.nodeTeam.to({
                userId: get(res2, "data.pairingUserId"),
              })
            );
          })
          .catch((err) => {
            console.log(err);
            // message.error(get(err, "message"));
            if (get(err, "errorCode")) {
              message.error(t(get(err, "errorCode"), sourceKey.pairing));
            } else {
              message.error(get(err, "message"));
            }

            setLoading(false);
          });

        // getPairingUser(
        //   1,
        //   0,
        //   {
        //     username: toLower(sponsorUsername),
        //   },
        //   accessKey
        // )
        //   .then((res) => {
        //     let sponsor_data = get(res, "data");
        //     if (isEmpty(sponsor_data)) {
        //       message.error(t("sponsorUsernameInvalid", sourceKey.pairing));
        //       setLoading(false);
        //     } else {
        //       sponsor_data = sponsor_data[0];
        //       getPlacements(1, 0, {
        //         username: toLower(get(values, "placement")),
        //       })
        //         .then((res1) => {
        //           let placement_data = get(res1, "data");
        //           if (isEmpty(placement_data)) {
        //             message.error(t("placementNotfound", sourceKey.pairing));
        //             setLoading(false);
        //           } else {
        //             placement_data = placement_data[0];
        //             let correctValues = {};
        //             correctValues = {
        //               username: toLower(get(values, "username")),
        //               name: get(values, "username"),
        //               email: get(values, "email"),
        //               password: get(values, "password"),
        //               sponsorName: get(values, "sponsor"),
        //               placementName: toLower(get(values, "placement")),
        //               contactNo: get(values, "contact"),
        //               // packageId: get(values, "package"),
        //               position: get(values, "position"),
        //               requestUserId: get(user, "profile.pairingUserId"),
        //               parentId: get(user, "_id"),
        //             };
        //             // console.log(correctValues);

        //             createUser(correctValues)
        //               .then((res2) => {
        //                 // console.log(res2);
        //                 setLoading(false);
        //                 message.success(t("success"));
        //                 // navigate(
        //                 //   routes.nodeTeam.to({ userId: get(res2, "_id") })
        //                 // );
        //                 window.location.assign(
        //                   routes.nodeTeam.to({
        //                     userId: get(res2, "data.pairingUserId"),
        //                   })
        //                 );
        //               })
        //               .catch((err) => {
        //                 console.log(err);
        //                 // message.error(get(err, "message"));
        //                 if (get(err, "errorCode")) {
        //                   message.error(
        //                     t(get(err, "errorCode"), sourceKey.pairing)
        //                   );
        //                 } else {
        //                   message.error(get(err, "message"));
        //                 }

        //                 setLoading(false);
        //               });
        //           }
        //         })
        //         .catch((err) => {
        //           console.log(err);
        //         });
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     setLoading(false);
        //   });
      })
      .catch((err) => {
        forEach(get(err, "errorFields"), (item) => {
          message.error(get(item, "errors[0]"));
          setLoading(false);
        });
      });
  }

  function validatePlacementKeyIn() {
    // let placement = e.target.value;
    // console.log("change", placement);
    let placement = form.getFieldValue("placement");
    let position = form.getFieldValue("position");
    let sponsorName = form.getFieldValue("sponsor");
    let positionGet = [];
    // console.log(position);
    if (!isEmpty(placement)) {
      clearTimeout(timeoutFunc);
      timeoutFunc = setTimeout(() => {
        if (!isEmpty(position)) {
          validatePlacement({
            placementName: toLower(placement),
            position: position,
            sponsorName: sponsorName,
          })
            .then((res) => {
              // console.log(res);
              message.success(t("validPlacement", sourceKey.pairing), 10);
            })
            .catch((err) => {
              console.log(err);
              message.error(t(err?.errorCode, sourceKey.pairing), 10);
              // form.setFields([
              //   {
              //     name: "placement",
              //     errors: [t(err?.errorCode, sourceKey.pairing)],
              //   },
              // ]);
            });
        } else {
          message.info(t("validatePlacement", sourceKey.pairing));
        }
      }, 1000);
    }
  }

  function validateSponsorNameData(e) {
    let sponsorName = e.target.value;
    if (!isEmpty(sponsorName)) {
      clearTimeout(timeoutFunc);
      timeoutFunc = setTimeout(() => {
        validateSponsorName({
          sponsorName: sponsorName,
          requestUserId: get(user, "profile.pairingUserId"),
        })
          .then((res) => {
            // console.log("sponsor", res);
            if (disableInput === false) {
              message.success(t("validSponsorName", sourceKey.pairing), 10);
              setPlacementDisabled(false);
            } else {
              validatePlacementKeyIn();
            }
          })
          .catch((err) => {
            console.log(err);
            form.setFields([
              {
                name: "sponsor",
                errors: [t(err?.errorCode, sourceKey.pairing)],
              },
            ]);
            // message.error(t(err?.errorCode, sourceKey.pairing));
            if (disableInput === false) {
              setPlacementDisabled(true);
              form.setFieldsValue({ position: "", placement: "" });
            }
          });
      }, 1000);
    } else {
      if (disableInput === false) {
        setPlacementDisabled(true);
        form.setFieldsValue({ position: "", placement: "" });
      }
    }
  }

  function validateUsernameData(e) {
    let username = e.target.value;
    // console.log("username",username)
    if (!isEmpty(username)) {
      clearTimeout(timeoutFunc);
      timeoutFunc = setTimeout(() => {
        validateUserName({
          username: trim(toLower(username)),
        })
          .then((res) => {
            // console.log("res username", res);
            // let data = get(res, "data");
            // if (data.length !== 0) {
            //   form.setFields([
            //     {
            //       name: "username",
            //       errors: [
            //         t("username: value already exists.", sourceKey.pairing),
            //       ],
            //     },
            //   ]);
            // }
          })
          .catch((err) => {
            console.log(err);
            // message.error(t(err?.errorCode, sourceKey.pairing));
            form.setFields([
              {
                name: "username",
                errors: [
                  t("username: value already exists.", sourceKey.pairing),
                ],
              },
            ]);
          });
      }, 1000);
    }
  }

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <RequirePairingAuthWrapper>
            <NavHeader
              suffix={
                <span className="inline-block text-base text-right  font-semibold">
                  {/* 注册{" "}
                  <MediaQuery maxWidth={440}>
                    <br />
                  </MediaQuery> */}
                  {t("register", sourceKey.pairing)}
                </span>
              }
              showBack={true}
            >
              <div className="pb-5">
                <div className=" px-5">
                  <div className="">
                    <Form
                      form={form}
                      autoComplete="off"
                      style={{ color: "black" }}
                    >
                      <div className="grid grid-cols-4 gap-1">
                        <div className="col-span-2">
                          <div className="">
                            {" "}
                            {t("username", sourceKey.pairing)} :{" "}
                            <a style={{ color: "red" }}>*</a>
                          </div>
                          <div className="my-3">
                            <Form.Item
                              name="username"
                              initialValue=""
                              autoComplete="new-user"
                              style={{ margin: 0 }}
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <React.Fragment>
                                      {/* 请填写用戶名字
                                      <br /> */}
                                      {t("usernameRequired", sourceKey.pairing)}
                                    </React.Fragment>
                                  ),
                                },
                                {
                                  pattern: new RegExp(
                                    /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g
                                  ),
                                  message: t("alphanumeric", sourceKey.pairing),
                                },
                                {
                                  validator: (_, value) => {
                                    form.setFieldsValue({
                                      username: toLower(value?.trim()),
                                    });
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            >
                              <Input
                                placeholder=""
                                defaultValue={""}
                                autoComplete="do-not-autofill"
                                className={"input-border"}
                                style={{ textTransform: "lowercase" }}
                                maxLength="128"
                                onChange={(e) => {
                                  let value = e.target.value;
                                  if (value.length === 128) {
                                    message.info(
                                      t("maxlenghtUsername", sourceKey.pairing)
                                    );
                                  }
                                  validateUsernameData(e);
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-span-2">
                          {/* <div className="">
                            {" "}
                            {t("name", sourceKey.pairing)} :
                          </div>
                          <div className="my-3">
                            <Form.Item
                              name="name"
                              style={{ margin: 0 }}
                              initialValue=""
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <React.Fragment>
                                     
                                      {t("nameRequired", sourceKey.pairing)}
                                    </React.Fragment>
                                  ),
                                },
                              ]}
                            >
                              <Input
                                placeholder=""
                                autoComplete="off"
                                className={"input-border"}
                              />
                            </Form.Item>
                          </div> */}
                        </div>

                        <div className="col-span-4">
                          <div className="">
                            {" "}
                            {t("sponsorUsername", sourceKey.pairing)} :{" "}
                            <a style={{ color: "red" }}>*</a>
                          </div>
                          <div className="my-3">
                            <Form.Item
                              name="sponsor"
                              style={{ margin: 0 }}
                              initialValue=""
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <React.Fragment>
                                      {/* 请填写推荐用戶
                                      <br /> */}
                                      {t("sponsorUsername", sourceKey.pairing)}
                                    </React.Fragment>
                                  ),
                                },
                                {
                                  validator: (_, value) => {
                                    form.setFieldsValue({
                                      sponsor: toLower(value?.trim()),
                                    });
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                            >
                              <Input
                                placeholder=""
                                autoComplete="off"
                                className={"input-border"}
                                onChange={validateSponsorNameData}
                                // disabled={disableInput}
                              />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-span-2">
                          <div className="">
                            {/* 节点安置  */}
                            {t("placement", sourceKey.pairing)} :{" "}
                            <a style={{ color: "red" }}>*</a>
                          </div>
                          <div className="rounded-xl my-3">
                            <Form.Item
                              name="placement"
                              style={{ margin: 0 }}
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <React.Fragment>
                                      {/* 请填写节点安置 <br /> */}
                                      {t(
                                        "placementRequired",
                                        sourceKey.pairing
                                      )}
                                    </React.Fragment>
                                  ),
                                },
                              ]}
                            >
                              <Input
                                placeholder=""
                                autoComplete="off"
                                className={"input-border"}
                                disabled={placementDisabled}
                                style={{
                                  color: `${
                                    placementDisabled === true ? "grey" : ""
                                  }`,
                                }}
                                onChange={() => {
                                  validatePlacementKeyIn();
                                }}
                                // addonAfter={
                                //   <div style={{ cursor: "pointer" }}>
                                //     <SearchOutlined
                                //       onClick={() => {
                                //         validatePlacement();
                                //       }}
                                //     />
                                //   </div>
                                // }
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="">
                            {/* 节点位置  */}
                            {t("position", sourceKey.pairing)} :{" "}
                            <a style={{ color: "red" }}>*</a>
                          </div>
                          <div className="rounded-3xl my-3">
                            <Form.Item
                              className="my-select-container"
                              name="position"
                              style={{ margin: 0 }}
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <React.Fragment>
                                      {/* 请选择节点位置 <br /> */}
                                      {t("selectPosition", sourceKey.pairing)}
                                    </React.Fragment>
                                  ),
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                style={{
                                  width: "100%",
                                  color: `${
                                    placementDisabled === true ? "grey" : ""
                                  }`,
                                }}
                                // style={{ width: "100%" }}
                                placeholder=""
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={placementDisabled}
                                onChange={() => {
                                  validatePlacementKeyIn();
                                }}
                              >
                                {map(positionList, (item, index) => {
                                  return (
                                    <Select.Option
                                      value={get(item, "key") || ""}
                                      key={`option-${index}`}
                                    >
                                      {get(item, "value")}
                                    </Select.Option>
                                  );
                                })}
                                {/* <Select.Option value={"L"} key={`option-${1}`}>
                                {"Left"}
                              </Select.Option>
                              <Select.Option value={"R"} key={`option-${2}`}>
                                {"Right"}
                              </Select.Option> */}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-span-4">
                          <div className="">
                            {t("temporaryPassword", sourceKey.pairing)} : <a style={{ color: "red" }}>*</a>
                          </div>
                          {/* <div className="text-xs my-3">
                           密码条件: 8 到 16 个字母（含 1 个小写字母、1
                          个大写字母和 1 个数字） <br /> Password criteria:
                          Between 8 to 16 characters inclusive (include 1
                          lowercase, 1 uppercase, and 1 number)
                        </div> */}
                          <div className="my-3">
                            <Form.Item
                              name="password"
                              autoComplete="new-password"
                              required
                              style={{ margin: 0 }}
                              initialValue=""
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <React.Fragment>
                                      {/* 请填写密码
                                      <br /> */}
                                      {t("passwordRequired")}
                                    </React.Fragment>
                                  ),
                                },
                                // {
                                //   pattern: new RegExp(
                                //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,30}$/
                                //   ),
                                //   message: t("passwordValidation"),
                                // },
                              ]}
                            >
                              <Input.Password
                                placeholder={t(
                                  "simplePassword",
                                  sourceKey.pairing
                                )}
                                defaultValue={""}
                                autoComplete="new-password"
                                maxLength="30"
                                iconRender={(visible) =>
                                  visible ? (
                                    <EyeTwoTone />
                                  ) : (
                                    <EyeInvisibleOutlined />
                                  )
                                }
                                onChange={(e) => {
                                  let value = e.target.value;
                                  if (value.length === 30) {
                                    message.info(
                                      t("maxlenghtPasword", sourceKey.pairing),
                                      3
                                    );
                                  }
                                }}
                                className={"input-border"}
                              />
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-span-4">
                          {/* <div className="">{t("email")} :</div>
                          <div className="my-3">
                            <Form.Item
                              name="email"
                              style={{ margin: 0 }}
                              required
                              initialValue=""
                              rules={[
                                {
                                  required: true,
                                  message: (
                                    <React.Fragment>
                                     
                                      {t("emailRequired")}
                                    </React.Fragment>
                                  ),
                                },
                                {
                                  type: "email",
                                  message: (
                                    <React.Fragment>
                                    
                                      {t("invalidEmail")}
                                    </React.Fragment>
                                  ),
                                },
                              ]}
                            >
                              <Input
                                placeholder=""
                                autoComplete="off"
                                className={"input-border"}
                              />
                            </Form.Item>
                          </div> */}
                        </div>

                        {/* <div className="col-span-4">
                          <div className="">
                            {t("contactNo", sourceKey.pairing)} :
                          </div>
                          <div className="my-3">
                            <Form.Item
                              name="contact"
                              style={{ margin: 0 }}
                              required
                              initialValue=""
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: (
                              //       <React.Fragment>
                              //         {t("contactRequired", sourceKey.pairing)}
                              //       </React.Fragment>
                              //     ),
                              //   },
                              // ]}
                            >
                              <Input
                                placeholder=""
                                autoComplete="off"
                                className={"input-border"}
                              />
                            </Form.Item>
                          </div>
                        </div> */}
                      </div>
                    </Form>
                  </div>

                  <div className="flex justify-center items-center mt-5">
                    <Button
                      className="main-button-color rounded-4xl"
                      block
                      // shape="round"
                      onClick={(e) => {
                        submitRegister();
                      }}
                    >
                      {t("register", sourceKey.pairing)}
                    </Button>
                  </div>
                  {/* <div className="flex items-center mt-3">已有账号？</div>
                <div className="flex items-center">
                  <span className="mr-3">Already have an account?</span>
                  <Link
                    to={routes.login.to()}
                    state={{ prevPath: location.pathname }}
                  >
                    <span className="underline text-blue-400">登入 Login</span>
                  </Link>
                </div> */}
                </div>
              </div>
            </NavHeader>
          </RequirePairingAuthWrapper>
          {/* <TutorialVideoModal
            type="register"
            visible={videoShow}
            onClose={() => {
              setVideoShow(false);
            }}
          /> */}
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
